import {useEffect, useRef, useState} from "react";

const Dialog = ({ id, isOpen, onClose, children }) => {
  const modalRef = useRef();
  const [isModalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    console.log("dialog changing state", isModalOpen);
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen,isOpen]);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  return <dialog ref={modalRef} onKeyDown={handleKeyDown} id={id} >
    {children}
  </dialog>
}

export default Dialog