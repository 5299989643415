import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GiftcardUser from "../models/giftcarduser";
import { toCamelCase } from "../utils/inputUtils";
import "./giftcarduser-form.css";
import API from "../api";
import Dialog from "../utils/dialog";
import { COUNTRIES } from "../utils/constants";



const CardUserRegistrationForm = forwardRef(
  ({ token, visibility, handleReset, handleSuccess },
    ref
  ) => {
    const [t, _] = useTranslation();
    const api = API();
    const [formData, setFormData] = useState([])
    const [regDialogOpen, setRegDialogOpen] = useState(false)

    const showDialog = (msg) => {
      alert(msg)
    }
    const handleServerError = (error) => {
      let message = t('giftcard.activationServerError');
      if (typeof error === 'string') {
        message = error;
      } else if (error[0] && error[0].loc) {
        const field = error[0].loc[1];
        const fieldLabel = t(toCamelCase(field))
        const errMsg = error[0].msg || error[0].ctx.error;
        message = `${t('giftcard.errorInField', { fieldLabel: fieldLabel, errMsg: errMsg })}`
      }
      showDialog(message)
    }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formModel = new FormData(e.target);
    const data = Object.fromEntries(formModel.entries());
    const user = new GiftcardUser(data);
    const activationCode = data.activation_code || "";
    const isRegistrationRequired = !!data['email']
    console.log("registration required? ", isRegistrationRequired);
    let registrationResult = null;
    let activationResult = null;
    if(formData.activation_code && !activationCode){
      showDialog(t('giftcard.activationCodeRequired'));
      return;
    }
    if(isRegistrationRequired){
      console.log("Registering card user:", user, activationCode);
      registrationResult = await api.registerCardUser(user.toObject(), token);
      if(!registrationResult.id) {
        handleServerError(registrationResult.error);
        return;
      }
    }
    if (!isRegistrationRequired || registrationResult.id) {
      activationResult = await api.activateCard(token, activationCode);
    }
    if(activationResult && activationResult.actionCode === api.ACTION_CODE.OK) {
      console.log("Card user activated:", activationResult)
      handleReset();
      setRegDialogOpen(false);
      return
    }
    if(activationResult && activationResult.actionCode) {
      handleServerError(registrationResult.actionCodeDescription);
      return;
    }
    if(activationResult && activationResult.message){
      handleServerError(activationResult.message);
      return;
    }
    showDialog(t('giftcard.activationServerError'));


    };

    const handleActivateLaterClick = () => {
      console.log("handleActivateLaterClick")
      setRegDialogOpen(false)
      handleReset();
    }

    const handleClickActivate = () => {
      setRegDialogOpen(true)
    }

    const handleCloseDialog = () => {
      setRegDialogOpen(false)
    }

    const ErrorMsg = ({ error }) => {
      if (error) {
        return <div className="error">{t(error.message)}</div>
      }
      return null;
    }

    const FormField = ({ name, type = 'text', required = true, error = '', defaultValue }) => {
      const [searchValue, setSearchValue] = useState('');
      const [isDropdownOpen, setIsDropdownOpen] = useState(false);
      const [selectedCountryCode, setSelectedCountryCode] = useState('');
      const langKey = toCamelCase(name);

      // Helper function to render error message if exists
      const renderErrorMsg = () => error && <ErrorMsg error={error} />;

      // If the field is for mobile number, render mobile input with country code selector
      if (name === 'mobile_number') {
        return (
          <span className={`formField field-${name} hasError-${!!error}`}>
            <label>{t(`giftcard.${langKey}`)}</label>
            <div className="mobile-number-input">
              {/* Country code dropdown */}
              <select
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.target.value)}
                className="country-code-select"
              >
                {COUNTRIES.map((country) => (
                  <option key={country.phoneCode} value={country.phoneCode}>
                    {country.phoneCode}
                  </option>
                ))}
              </select>
              {/* Mobile number input field */}
              <input
                type="text"
                name={name}
                defaultValue={defaultValue}
                className="mobile-number-input-field"
              />
            </div>
      
            {/* Display error message if any */}
            {renderErrorMsg()}
          </span>
        );
      }

      // If the field is for country of birth or country of residency, render country select input with search functionality
      if (name === "country_of_birth" || name === "country_of_residency") {
        const filteredCountries = COUNTRIES.filter((country) =>
          t(`countries.${country.name}`).toLowerCase().includes(searchValue.toLowerCase())
        );

        // Function to handle country selection
        const handleSelect = (code, name, phoneCode) => {
          setIsDropdownOpen(false);
          setSearchValue(name);  // Set the selected country name as the search value
          setSelectedCountryCode(phoneCode); // Update the selected country code
          console.log(code); // Handle the selected country code (e.g., update state)
        };

        return (
          <span className={`formField field-${name} hasError-${!!error}`}>
            <label>{t(`giftcard.${langKey}`)}</label>
            <div className="custom-dropdown">
              {/* Country search input */}
              <input
                type="text"
                placeholder={t("giftcard.selectCountry")}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}  // Update search value on input change
                onFocus={() => setIsDropdownOpen(true)} // Show dropdown when input is focused
                onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)} // Close dropdown after a delay to allow option click
              />
              {/* Dropdown options for country search */}
              {isDropdownOpen && (
                <ul className="dropdown-options">
                  {filteredCountries.length > 0 ? (
                    filteredCountries.map((country) => (
                      <li
                        key={country.code}
                        onClick={() => handleSelect(country.code, t(`countries.${country.name}`), country.phoneCode)}
                        className="dropdown-option"
                      >
                        {t(`countries.${country.name}`)}
                      </li>
                    ))
                  ) : (
                    <li className="dropdown-option no-match">{t("giftcard.noMatches")}</li>
                  )}
                </ul>
              )}
            </div>
            {/* Display error message if any */}
            {renderErrorMsg()}
          </span>
        );
      }

      // Default input field rendering for other fields
      return (
        <span className={`formField field-${name} hasError-${!!error}`}>
          <label>{t(`giftcard.${langKey}`)}</label>
          {/* Render input based on field type */}
          <input
            type={type}
            name={name}
            defaultValue={type === 'checkbox' ? !!defaultValue : defaultValue}
            defaultChecked={type === 'checkbox' && defaultValue}
          />
          {/* Display error message if any */}
          {renderErrorMsg()}
        </span>
      );
    };


    const loadConfig = async () => {
      const cfg = await api.getClientConfig();
      console.log("Activation form fields:", cfg.activationForm)
      if (cfg.activation_form) {
        setFormData(cfg.activation_form)
      }
    }

    useEffect(() => {
      loadConfig()
    }, []);

    const RegistrationDialog = ({ id, isOpen, formData, onConfirm, onCancel }) => {
      const [t, _] = useTranslation();
      const fields = formData.map(
        (field) => <FormField
          key={field.key}
          name={field.key}
          type={field.type || "text"}
          required={!field.optional}
          error={field.error}
          defaultValue={field.defaultValue} />
      );
      const description = formData.length > 1 ?
        t('giftcard.registrationFormDescription', { token: token }) :
        t('giftcard.activationCodeDescription', { token: token });


      return <Dialog isOpen={isOpen} id={id} onClose={handleCloseDialog}>
        <h2>{t('giftcard.registrationForm')}</h2>
        <p>{description}</p>
        <form onSubmit={handleSubmit} onReset={handleActivateLaterClick}>
          {fields}
          <button type="submit" className="button shown">{t('giftcard.submitActivationForm')}</button>
          <button type="reset" className="button aux">{t('giftcard.activateLater')}</button>
        </form>
      </Dialog>
    }

    console.log("CardUserRegistrationForm rendering", regDialogOpen)

    return (
      <div className="registrationForm" ref={ref}>
        <button className="button shown activateBtn" onClick={handleClickActivate}>{t('giftcard.activateCard')}</button>
        <RegistrationDialog formData={formData} isOpen={regDialogOpen} id="registrationDialog" onClose={handleCloseDialog} />
      </div>
    )
  });


export default CardUserRegistrationForm;