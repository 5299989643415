import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import "./order-components.css";
import format from "../utils/dateFormat";
import {GiftCardBatch, GiftCardsOrder} from "../../models/orders";
import sanitize, {pasteClipboardIntoCards} from "../utils/inputUtils";
import Dialog from "../utils/dialog";

const ICONS = {
  UP: String.fromCharCode(0x25B2),
  DOWN: String.fromCharCode(0x25BC),
  PLUS: String.fromCharCode(0x002B)
}



const Card = ({card, onPaste, currency = "", editorMode=""}) => {
  const indexPlusOne = card.index + 1;
  const [className, setClassName] = useState("card");
  const getClass = (c) => {
    return `card empty-${c.isEmpty} valid-${c.isValid} fulfilled-${c.isFulfilled} ` +
      `active-${c.isActive} hasToken-${c.hasValidToken} hasAmount-${c.hasAmount} hasCardholder-${c.hasCardholder}`
  }
  const isAssigningCards = (editorMode === GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS)

  const isReadOnly = [
    GiftCardsOrder.ACTION_ENUM.OPEN,
    GiftCardsOrder.ACTION_ENUM.COMMIT_ORDER
  ].includes(editorMode);

  const handleChange = (e) => {
    const field = e.target.name;
    const val = sanitize(field, e.target.value);
    card[field] = val;
    e.target.value = val
    setClassName(getClass(card))
  }
  const handleKeyUp = (e) => {
    if(e.key === "Enter") {
      const allTokenInputs = [...document.querySelectorAll(".card input[name=token]")]
      const currentInputIndex = allTokenInputs.indexOf(e.target)
      if(currentInputIndex < allTokenInputs.length -1)
        allTokenInputs[currentInputIndex+1].focus()
    }
  }

  useEffect(() => {
    setClassName(getClass(card))
  }, [card]);

  return <div className={className}
          data-id={card.id}
          data-index={card.index}
          data-error={card.lastError}
          onPaste={onPaste}
          onBlur={handleChange}>
      <input name="token"
             defaultValue={card.token}
             placeholder={`token ${indexPlusOne}`}
             readOnly={isReadOnly}
             onKeyUp={handleKeyUp}
             autoComplete="off"/>
      <input name="seqenceNumber"
             defaultValue={card.sequenceNumber}
             placeholder={`sequenceNumber ${indexPlusOne}`}
             tabIndex={isAssigningCards ? -1 : ""}
             readOnly={isReadOnly}
             autoComplete="off"/>
      <span className="inputGroup">
        <input name="loadAmount"
               defaultValue={parseFloat(card.loadAmount).toFixed(2) || 0}
               placeholder={`amount ${indexPlusOne}`}
               tabIndex={isAssigningCards ? -1 : ""}
               readOnly={isReadOnly}
               autoComplete="off"/>
        {currency}
      </span>
      <input name="cardholderName"
             defaultValue={card.cardholderName}
             placeholder={`cardholder ${indexPlusOne}`}
             tabIndex={isAssigningCards ? -1 : ""}
             readOnly={isReadOnly}
             autoComplete="off"/>
      <div className="errorMsg">{card.lastError}</div>
  </div>
}

const CardBatch = ({batch, deleteClickHandler, editorMode, onPaste}) => {
  const [t, _] = useTranslation();
  const [isVisible, setIsVisible] = useState(batch.isVisible)
  const className = `batch amountsEditable-${batch.useIndividualLoadAmounts > 0} namesEditable-${batch.useIndividualCardholders}`
  const toggleBatchHandler = (e) => {
    batch.isVisible = !isVisible;
    setIsVisible(!isVisible);

  }
  return <div className={className} data-index={batch.index} >
    <div className="header">
      <span><label>{t('orders.batchRef')} {batch.id}</label><b>batch {batch.index + 1}</b></span>
      <span className="batchSummary">{batch.summary} {batch.currency}</span>
      <span>
        <button className="deleteBatchBtn warn" data-index={batch.index} onClick={deleteClickHandler}
            tabIndex={editorMode===GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS ? "-1" : ""}>
          {t('orders.deleteBatch')}
        </button>
      </span>
      <span>
        <button className="toggleBatchBtn" data-index={batch.index} onClick={toggleBatchHandler}
            tabIndex={editorMode===GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS ? "-1" : ""}>
          {isVisible ? ICONS.UP + t('orders.hideCards') : ICONS.DOWN + t('orders.showCards')}
        </button>
      </span>
    </div>
    <div className={`cards shown-${isVisible}`}>
      {batch.cards.map((card, i) =>
        <Card card={card}
              currency={batch.currency}
              onPaste={onPaste}
              editorMode={editorMode} key={card.id || i}/>
    )}
    </div>
  </div>
}

const AddBatchForm = ({order, handleAddBatch}) => {
  const [t, _] = useTranslation();
  const [useIndividualNames, setUseIndividualNames] = useState(false);
  const [useIndividualAmounts, setUseIndividualAmounts] = useState(false);
  const [loadAmount, setLoadAmount] = useState(100);
  const [cardCount, setCardCount] = useState(10);

  const handleLoadChange = (e) => {
    const amount = sanitize(e.currentTarget.name, e.currentTarget.value);
    setLoadAmount(amount);
    e.target.value = amount
  }
  const handleCountChange = (e) => {
    const count = sanitize(e.currentTarget.name, e.currentTarget.value);
    setCardCount(count);
    e.target.value = count;
  }
  const handleAddBatchClick = (e) => {
    const newBatch = new GiftCardBatch(loadAmount, order.currency, order.id, order.cardBatches.length+1);
    newBatch.cardCount = cardCount
    newBatch.useIndividualCardholders = useIndividualNames
    if(handleAddBatch) handleAddBatch(newBatch);
  }
  const handleIndividualNamesChange = (e) => {
    setUseIndividualNames(e.target.checked);
  }
  const handleIndividualAmountsChange = (e) => {
    setUseIndividualAmounts(e.target.checked);
    setLoadAmount(0);
  }


  return <div className="batch new" data-index={0}>
    <div className="header">
      <span><b>{t('orders.newBatch')}</b></span>
      <span></span>
    </div>
    <div className="controls">
      <label id="cardCountIn">
        <input type="number" defaultValue={cardCount} name="cardCount" min="1" max="1000" onBlur={handleCountChange}/>
        cards
      </label>
      <label id="loadAmountIn" className={useIndividualAmounts ? "hiddenContents" : ""}>
        load amount: <input type="number" defaultValue={loadAmount}
                            readOnly={useIndividualAmounts}
                            name="loadAmount" min="0" max="10000"
                            onBlur={handleLoadChange}/>
        {order.currency}
      </label>
      <label id="individualAmountsCB">
        <input type="checkbox" name="individualAmounts"
               defaultChecked={useIndividualAmounts}
               onChange={handleIndividualAmountsChange}/>
        individual amounts
      </label>
      <label id="individualCardholdersCB">
        <input type="checkbox" name="individualCardholders"
               defaultChecked={useIndividualNames}
               onChange={handleIndividualNamesChange}/>
        add cardholder names
      </label>
    </div>
    <div className="controls">
      <span className="spacer"></span>
      <button className={order.cardBatches && order.cardBatches.length ? "aux" : "main"}
              id="addBatchBtn"
              onClick={handleAddBatchClick}>{ICONS.PLUS} {t('orders.addBatch')}</button>

    </div>
  </div>
}


const OrderHeader = ({order}) => {
  const {t, i18n} = useTranslation();
  const created = format(order.createdDate);
  const handleTitleChange = (e) => {
    order.title = e.target.value;
  }
  const handleEmailsChange = (e) => {
    order.notifyEmails = e.target.value;
  }
  const handleLoadImmediatelyChange = (e) => {
    order.loadOnFulfillment = e.target.checked;
  }
  const handleActivateOnFulfillmentChange = (e) => {
    order.activateOnFulfillment = e.target.checked;
  }
  const isEditable = [GiftCardsOrder.STATUS_ENUM.OPEN, GiftCardsOrder.STATUS_ENUM.CARDS_ASSIGNED].includes(order.status);

  return <><div className="header" key={order.id}>
      <span className="reference"><label>{t('orders.reference')}</label>{order.reference}</span>
      <span className="createdDate"><label>{t('orders.created')}</label>{created}</span>
      <span className="status"><label>{t('orders.status')}</label>{t(`orders.orderStatus-${order.status}`)}</span>
      <span className="summary"><label>{t('orders.summary')}</label>{order.summary}</span>
      <span className="totalLoad"><label>{t('orders.totalLoad')}</label>{order.totalLoad} {order.currency}</span>
    </div>
    <div className="header" key={"B"+order.id}>
      <span className="title"><label>{t('orders.title')}</label>
        <input name="title" onChange={handleTitleChange} type="text"
               readOnly={isEditable ? false : "readonly"}
               defaultValue={order.title || t('orders.untitled')}/>
      </span>
      <span className="emails"><label>{t('orders.notifyEmails')}</label>
        <input name="title" onChange={handleEmailsChange} type="text"
               readOnly={isEditable ? false : "readonly"}
               defaultValue={order.notifyEmails}/>
      </span>
      <span className="loadImmediately yesno"><label>{t('orders.loadImmediately')}</label>
          <input type="checkbox" name="loadImmediatelyCB"
                 readOnly={isEditable ? false : "readonly"}
                 defaultChecked={order.loadOnFulfillment}
                 onChange={handleLoadImmediatelyChange}/>
      </span>
      <span className="activateImmediately yesno"><label>{t('orders.activateImmediately')}</label>
          <input type="checkbox" name="activateOnFulfillmentCB"
                 readOnly={isEditable ? false : "readonly"}
                 defaultChecked={order.activateOnFulfillment}
                 onChange={handleActivateOnFulfillmentChange}/>
      </span>
  </div></>
}

const OrderControls = ({order, clickHandler, editorMode}) => {
  const {t, i18n} = useTranslation();
  const commitables = [GiftCardsOrder.STATUS_ENUM.OPEN, GiftCardsOrder.STATUS_ENUM.CARDS_ASSIGNED]
  let assignBtnClass = `${order.canEditCards && editorMode !== GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS?
    (order.hasAllCardsAssigned ? "aux" : "aux suggest") : "disabled"}`;
  let cancelBtnClass = order.canCancel ?
    "aux warn" : "disabled";
  let commitBtnClass = `${(commitables.includes(order.status) && order.hasAllCardsValid && order.totalCardCount > 0) ?
    "aux suggest" : "disabled"}`;
  let loadBtnClass = `${(order.status === GiftCardsOrder.STATUS_ENUM.COMMITED && order.hasAllCardsValid && order.totalCardCount > 0) ?
    "aux suggest" : "disabled"}`;
  let activateBtnClass = `${order.status === GiftCardsOrder.STATUS_ENUM.CARDS_LOADED ?
    "aux suggest" : "disabled"}`;
  let saveBtnClass = `${order.canSave ?
    "main" : "disabled"}`;
  const suggestText = t('orders.suggestedAction')
  const doSuggestFn = (cls) => cls.indexOf("suggest") >= 0 ? suggestText : ""

  return <><div className="controls">
    <button name={GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS} className={assignBtnClass}
            onClick={clickHandler} title={doSuggestFn(assignBtnClass)}>
      {t('orders.assignCards')}
    </button>
    <button name={GiftCardsOrder.ACTION_ENUM.COMMIT_ORDER} className={commitBtnClass}
            onClick={clickHandler} title={doSuggestFn(commitBtnClass)}>
      {t('orders.commitOrder')}
    </button>
    <button name={GiftCardsOrder.ACTION_ENUM.LOAD_CARDS} className={loadBtnClass}
            onClick={clickHandler} title={doSuggestFn(loadBtnClass)}>
      {t('orders.loadCards')}
    </button>
    <button name={GiftCardsOrder.ACTION_ENUM.ACTIVATE_CARDS} className={activateBtnClass}
            onClick={clickHandler} title={doSuggestFn(activateBtnClass)}>
      {t('orders.activateCards')}
    </button>
  </div>
  <div className="controls">
    <button name={GiftCardsOrder.ACTION_ENUM.CANCEL_ORDER} className={cancelBtnClass}
            onClick={clickHandler} title={doSuggestFn(cancelBtnClass)}>
      {t('orders.cancelOrder')}
    </button>
    <button name={GiftCardsOrder.ACTION_ENUM.SAVE_ORDER} className={saveBtnClass}
            onClick={clickHandler} title={doSuggestFn(saveBtnClass)}>
      {t('orders.saveOrder')}
    </button>
  </div>
  </>
}

const OrderHelp = ({order}) => {
  const [t, _] = useTranslation();
  return <div className="orderHelp">
    {t(`orders.orderHelp-${order.status}`)}
  </div>
}

const DeleteDialog = ({id, msg, onConfirm, onCancel}) => {
  const [t, _] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (e) => {
    const response = e.target.dataset.response;
    setIsOpen(false);
    if(response === "confirm") {
      onConfirm()
    } else {
      onCancel();
    }
  }

  useEffect(() => {
    setIsOpen(!!msg)
  }, [msg]);

  return <Dialog isOpen={isOpen} id={id} onClose={handleClose}>
    <h3>{msg}</h3>
    <div className="controls">
    <button className="aux" onClick={handleClose} data-response="cancel">{t('orders.cancel')}</button>
    <button className="main" onClick={handleClose} data-response="confirm">{t('orders.confirm')}</button>
      </div>
  </Dialog>
}

const ErrorDialog = ({msg, techInfo, clearCallback}) => {
  const [t, _] = useTranslation()
  const close = () => {
    clearCallback();
  }
  return <div id="errorDialog" className={msg ? "" : "hidden"} >
    <h3>{msg}</h3>
    <small>{techInfo}</small>
    <button className="main" onClick={close}>{t('orders.closeError')}</button>
  </div>
}

const doDialog = (msg, onConfirm, onCancel) => {
  return {
      msg:msg,
      onConfirm: onConfirm,
      onCancel: onCancel
    }
}

const Order = ({order, callbackHandler, closeHandler, editorMode}) => {
  const [batches, setBatches] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [dialog, setDialog] = useState({});
  const [refreshIndicator, setRefreshIndicator] = useState(0);
  const [t, _] = useTranslation();
  const handleAddBatch = (batch) => {
    batch.index = batches.length;
    order.cardBatches = batches.concat([batch]);
    setBatches(order.cardBatches);
    callbackHandler();
  }

  const deleteBatchClickHandler = (e) => {
    const index = parseInt(e.target.dataset.index);
    const batchToDelete = order.cardBatches[index];
    console.log("delete dialog", batchToDelete)
    setDialog(doDialog(
      t('orders.deleteDialog', {index:index + 1, summary:batchToDelete.summary}),
      ()=>{setDialog({}); deleteBatch()},
      ()=>{setDialog({});}
    ));


    const deleteBatch = () => {
      order.cardBatches = order.cardBatches
        .filter((_, i) => i !== index)
        .map((b, i) => {b.index = i; return b});
      console.log("deleted!", order.cardBatches)
      setBatches(order.cardBatches);
      callbackHandler();
    }
  }

  const controlBtnClickHandler = (e) => {
    setShowValidation(true);
    callbackHandler(e.target.name);
  }

  const handlePaste = (e) => {
    pasteClipboardIntoCards(e, order);
    setBatches(order.cardBatches);
    setRefreshIndicator(refreshIndicator + 1)
  }

  useEffect(() => {
    setShowValidation(false);
    setBatches(order && order.cardBatches ? order.cardBatches : []);
  }, [order]);

  if(!order) return null;

  return <div id="orderFormWrapper">
    <DeleteDialog id="deleteDialog" msg={dialog.msg} onCancel={dialog.onCancel} onConfirm={dialog.onConfirm} />

    <div className={`orderForm mode-${editorMode} status-${order.status} showValidation-${showValidation}`}>
      <OrderHeader order={order}/>
      <OrderHelp order={order}/>
      <OrderControls order={order} clickHandler={controlBtnClickHandler} editorMode={editorMode}/>
      <AddBatchForm order={order} handleAddBatch={handleAddBatch}/>
      <div className="batches">
        {batches ? batches.map(
          (batch, i) => {
            return <CardBatch batch={batch}
                              deleteClickHandler={deleteBatchClickHandler}
                              editorMode={editorMode}
                              onPaste={handlePaste}
                              key={i + '-' + refreshIndicator}/>
          }
        ) : ""}
      </div>
    </div>
  </div>;
}

export default Order;
export {ErrorDialog}