import React, {forwardRef, useState} from "react";
import { useTranslation } from "react-i18next";
import TypewriterComponent from "typewriter-effect";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faGlobe } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";

const LoadingSpinner = () => {
  return <div className="loader spinner"></div>
}
const CardArtwork = forwardRef((props, ref) => {
    return <div className={`cardArtwork isActive-${props.isActive}`} ref={ref}>
      <LoadingSpinner/>
    </div>
});


const BrandHeader = forwardRef((props, ref) => {
  return <div className={"brandHeader"} ref={ref}>
    <div className="logo"></div>
  </div>
});

const InfoPanel = forwardRef(({className='', text}, ref) => {
  return <div className={`infoPanel ${className}`} ref={ref}>
    <TypewriterComponent options={{strings:[text], loop:true, autoStart:true, delay:20, pauseFor:90000}}/>
  </div>
});

const VideoPanel = forwardRef(({videoUrl, className=''}, ref) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(videoUrl !== '')
  const handleClick = () => {
    setIsPlaying(!isPlaying);
  }
  const handleOnReady = () => {
    setIsLoading(false);
  }
  return <div onClick={handleClick} className={`videoPanel ${isLoading ? "loading" : "ready"}`}>
    <div className={"controls"}>
      {isPlaying ?
        <FontAwesomeIcon icon={faPause} /> :
        <FontAwesomeIcon icon={faPlay} />
      }
    </div>
    <LoadingSpinner/>
    <ReactPlayer url={videoUrl} width="100%" height="auto"
                 ref={ref} className={`videoPlayer ${className}`}
                 onReady={handleOnReady}
                 playing={isPlaying}/>
  </div>
});

const VideoWrapper = forwardRef(({className='', children}, ref) => {
  return <div className={`videoWrapper ${className}`} >
    <div className="progress" ref={ref}></div>
    {children}
  </div>
});


const trx2TableRow = (trx, i) => {
  const sign = trx.creditOrDebit === 'C' ? '+' : '-';
  const amount = Number.parseFloat(trx.transactionAmount).toFixed(2)
  const merchantName = trx.merchantName ? trx.merchantName.replaceAll('\\',' ') : ""
  return <div className={`trx ${trx.statusDescription}`} key={i}>
      <span className={"trxDate"}>{trx.transactionDate}</span>
      <span className={"trxTime"}>{trx.transactionTime}</span>
      <span className={"trxId"}>{trx.transactionId}</span>
      <span className={"trxAmount"}>{sign} {amount} {trx.currencyCode}</span>
      <span className={"trxMerchant"}>{merchantName}</span>
    </div>
}

const Statement = forwardRef(({className, transactions}, ref) => {
  const {t, i18next} = useTranslation();
  const hasTransactions = (transactions && transactions.length > 0);
  return <div className={`transactionList ${className}`} ref={ref}>
    <h2>{t('giftcard.transactionList')}</h2>
    {hasTransactions ? transactions.map(trx2TableRow) : t('giftcard.noTransactionsYet')}
  </div>

});


const LanguageSwitch = ({i18next, langs=[]}) => {
  //const {t, i18next} = useTranslation();
  ;
  const changeLanguageHandler = async (e) => {
     const lang = e.currentTarget.dataset.lang;
     await i18next.changeLanguage(lang);
     window.location.reload();
   }

   return <div className="languageSwitch">
     <FontAwesomeIcon icon={faGlobe} />
     {langs.map((lang, i) => <span data-lang={lang} key={i} onClick={changeLanguageHandler}>{lang}</span>)}
   </div>
}



const InputTokenForm = forwardRef(({className=''}, ref) => {
  const {t, i18next} = useTranslation();
  const [errorMsg, setErrorMsg] = useState('');
  const handleKeyUp = (e) => {
    setErrorMsg('');
    if(e.key === "Enter") handleTokenChange(e);
  }
  const handleTokenChange = (e) => {
    const token = e.target.value.replaceAll(/\D+/g, '');

    if (token.length === 9) window.location  = `/${token}`;
    else setErrorMsg(t('giftcard.tokenHasToBe9Digits'));
  }
  return <div className={`inputTokenForm ${className}`} ref={ref}>
    <label>{t('giftcard.enterToken')}</label>
    <input inputMode="numeric" maxLength="9" min={1} max={999999999} placeholder="000000000"
           onBlur={handleTokenChange}
           onKeyUp={handleKeyUp}
    />
    <span className={`error ${errorMsg ? '' : 'hidden'}`}>{errorMsg}</span>
  </div>
});

const MicrositeFooter = forwardRef(({className='', links=[], baseUrl=''}, ref) => {
  const {t, i18next} = useTranslation();
  links.forEach(link => {
    link.url = link.url.replace("{base_url}", baseUrl);
    link.id = link.id || link.text.replace(".", "-");
  })
  console.log("Footer links:", links);

  return <div className={`micrositeFooter ${className}`} ref={ref}>
    <div className="links">
      {links.map((link, i) => <a key={i} id={link.id} href={`${link.url}`}>
        <div className="icon"></div>
        <span>{t(link.text)}</span>
      </a>)}
    </div>
  </div>
});

export {VideoPanel, VideoWrapper, InfoPanel, BrandHeader, CardArtwork,
  Statement, LoadingSpinner, LanguageSwitch,
  InputTokenForm, MicrositeFooter}