const MAX_AMOUNT = 9999;
const MAX_CARDS = 1000;
const TOKEN_RE = "(http\\S+\\/)?(\\d{9})?"
const AMOUNT_RE = "(\\d{1,4}([,.]\\d{1,2})?)?"
const NAME_RE = "(\\t+([^\\r\\n\\t]+)?)?"
const PASTE_CARD_DATA_REGEX = new RegExp(`^\\s*${TOKEN_RE}\\W*(?<!\\d)${AMOUNT_RE}(?!\\d)${NAME_RE}$`, "gm");
//const PASTE_CARD_DATA_REGEX = /^\s*(http\S+\/)?(\d{9})?(\W*(\d{1,4}([,.]\d{1,2})?))?([^\r\n\w]*([^\r\n\t]+?))?$/gm


const toCamelCase = (str) => {
  return str.replace(/_./g, (m) => m[1].toUpperCase());
}

const cleanToken = (inputVal) => {
  const inputStr = String(inputVal);
  const m = inputStr.match(/\d{9}/)
  if(m){ return m[0]} else return inputVal
}

const cleanAmount = (inputVal) => {
  const inputStr = String(inputVal).replaceAll(",",".");
  let numVal = parseFloat(inputStr).toFixed(2);
  if(numVal > MAX_AMOUNT) numVal = MAX_AMOUNT;
  if(numVal < 0) numVal = 0;
  return numVal;
}

const cleanCardCount = (inputVal) => {
  const inputStr = String(inputVal)
  let numVal = parseInt(inputStr)
  if(numVal > MAX_CARDS) numVal = MAX_CARDS;
  if(numVal < 1) numVal = 1;
  return numVal;
}

const sanitize = (field, inputVal) => {
  switch(field){
    case "token": return cleanToken(inputVal);
    case "loadAmount": return cleanAmount(inputVal);
    case "cardCount": return cleanCardCount(inputVal);
    default: return inputVal
  }
}

const getTargetIndexInBatch = (target) => {
  const closestBatch = target.closest(".batch")
  const closestCard = target.closest(".card")
  return {
    batchIndex: parseInt(closestBatch.dataset.index),
    selectedCardIndex: parseInt(closestCard.dataset.index)
  }
}

const parsePasteClipboard = (pasted) => {
    const matches = [...pasted.matchAll(PASTE_CARD_DATA_REGEX)];
    console.log("pasted data matches:", matches);
    let _, token, amount, name;
    return matches.map((m, i) => {
      [_, _, token, amount, _, _, name] = [...m];
      console.log(m);
      return {token:token, loadAmount:amount, cardholderName:name};
    });
}


const pasteClipboardIntoCards = (e, order) => {
    e.preventDefault();
    const pasted = e.clipboardData.getData('text/plain');
    const items = parsePasteClipboard(pasted);
    const indices = getTargetIndexInBatch(e.target);
    let batch = order.cardBatches[indices.batchIndex];
    let cards = batch.cards;

    items.forEach((item, i) => {
      let card = cards[i + indices.selectedCardIndex];
      if(card){
        card.token = item.token || card.token;
        card.loadAmount = (batch.useIndividualLoadAmounts && item.loadAmount) || batch.loadAmount;
        card.cardholderName = (batch.useIndividualCardholders && item.cardholderName) || card.cardholderName;
        console.log(batch.useIndividualCardholders, item)
      }
    });


  }

export default sanitize;
export {pasteClipboardIntoCards, toCamelCase}


// const T1 = "123123123\r\n123123124\r\n123123125\r\n123123126\r\n123123127\r\n123123128\r\n123123129\r\n123123130\r\n123123131\r\n123123132\r\n123123133"
// if(parsePasteClipboard(T1).length !== 11) throw new Error("regex failed on token only data")
// const T2 = "123123123\t12.1\r\n123123124\t12.2\r\n123123125\t12.3\r\n123123126\t12.4\r\n123123127\t12.5\r\n123123128\t12.6\r\n123123129\t12.7\r\n123123130\t12.8\r\n123123131\t12.9\r\n123123132\t13\r\n123123133\t13.1";
// if(parsePasteClipboard(T2).length !== 11) throw new Error("regex failed on token and amount only data")
// const T3 = "123123123\t12.1\tJim Crow\r\n123123124\t12.2\tBaby Yoda\r\n123123125\t12.3\tAnn Applebaum\r\n123123126\t12.4\tJimi Hendrix\r\n123123127\t12.5\tPrince\r\n123123128\t12.6\tAndy Warhol\r\n123123129\t12.7\tCarol Vorderman\r\n123123130\t12.8\tJames Dean\r\n123123131\t12.9\tDick Cheney\r\n123123132\t13\tLaura Loomer\r\n123123133\t13.1\tKeir Starmer"
// if(parsePasteClipboard(T3).length !== 11) throw new Error("regex failed on token, amount and name data")