import React from 'react';
import GiftCardProvider from "./comps/store";
import ErrorBoundary from "./comps/error-boundary";
import './App.css'
import Router, {Route, RouterProvider} from "./comps/router";
import GiftcardHome from "./comps/screens/giftcard-home";
import Enquiry from "./comps/screens/enquiry";
import Admin from "./comps/screens/admin";



const Footer = () => {
    return <footer>&copy; Agantis 2024</footer>
}

function App() {

    const brandName = window.location.hostname.split(".").slice(0,-1).join(" ")

    return (
        <ErrorBoundary>
                <GiftCardProvider>
                    <div className={"giftcards " + brandName}>
                        <RouterProvider>
                            <Router>
                                <Route path="/admin" ><Admin/></Route>
                                <Route path="/enquiry" ><Enquiry/></Route>
                                <Route path="/:token"><GiftcardHome/></Route>
                                <Route path="/balance/:token" ><GiftcardHome/></Route>
                            </Router>
                        </RouterProvider>
                        <Footer/>
                    </div>
                </GiftCardProvider>
        </ErrorBoundary>
    );
}

export default App;