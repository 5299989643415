import React, {forwardRef, useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import GiftcardUser from "../models/giftcarduser";
import {toCamelCase} from "../utils/inputUtils";
import "./giftcarduser-form.css";
import API from "../api";
import Dialog from "../utils/dialog";

const CardUserRegistrationForm = forwardRef(
  ({token, visibility, handleReset, handleSuccess},
   ref
  ) => {
  const [t, _] = useTranslation();
  const api = API();
  const [formData, setFormData] = useState([])
  const [regDialogOpen, setRegDialogOpen] = useState(false)

  const showDialog = (msg) => {
    alert(msg)
  }
  const handleServerError = (error) => {
    let message = t('giftcard.activationServerError');
    if(typeof error === 'string') {
      message = error;
    } else if(error[0] && error[0].loc){
      const field = error[0].loc[1];
      const fieldLabel = t(toCamelCase(field))
      const errMsg = error[0].msg || error[0].ctx.error;
      message = `${t('giftcard.errorInField', {fieldLabel:fieldLabel, errMsg:errMsg})}`
    }
    showDialog(message)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formModel = new FormData(e.target);
    const data = Object.fromEntries(formModel.entries());
    const user = new GiftcardUser(data);
    const activationCode = data.activation_code || "";
    const isRegistrationRequired = !!data['email']
    console.log("registration required? ", isRegistrationRequired);
    let registrationResult = null;
    let activationResult = null;
    if(formData.activation_code && !activationCode){
      showDialog(t('giftcard.activationCodeRequired'));
      return;
    }
    if(isRegistrationRequired){
      console.log("Registering card user:", user, activationCode);
      registrationResult = await api.registerCardUser(user.toObject(), token);
      if(!registrationResult.id) {
        handleServerError(registrationResult.error);
        return;
      }
    }
    if (!isRegistrationRequired || registrationResult.id) {
      activationResult = await api.activateCard(token, activationCode);
    }
    if(activationResult && activationResult.actionCode === api.ACTION_CODE.OK) {
      console.log("Card user activated:", activationResult)
      handleReset();
      setRegDialogOpen(false);
      return
    }
    if(activationResult && activationResult.actionCode) {
      handleServerError(registrationResult.actionCodeDescription);
      return;
    }
    if(activationResult && activationResult.message){
      handleServerError(activationResult.message);
      return;
    }
    showDialog(t('giftcard.activationServerError'));


  };

  const handleActivateLaterClick = () => {
    console.log("handleActivateLaterClick")
    setRegDialogOpen(false)
    handleReset();
  }

  const handleClickActivate = () => {
    setRegDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setRegDialogOpen(false)
  }

  const ErrorMsg = ({error}) => {
    if (error) {
      return <div className="error">{t(error.message)}</div>
    }
    return null;
  }

  const FormField = ({name, type = 'text', required = true, error='', defaultValue}) => {
    const langKey = toCamelCase(name);
    return (
      <span className={`formField field-${name} hasError-${!!error}`}>
        <label>{t(`giftcard.${langKey}`)}</label>
        <input type={type} name={name}
               defaultValue={type === 'checkbox' ? !!defaultValue : defaultValue}
               defaultChecked={type === 'checkbox' && defaultValue}
        />
        <ErrorMsg error={error}/>
      </span>
    )
  }

  const loadConfig = async () => {
    const cfg = await api.getClientConfig();
    console.log("Activation form fields:", cfg.activationForm)
    if(cfg.activation_form){
      setFormData(cfg.activation_form)
    }
  }

  useEffect(() => {
    loadConfig()
  }, []);

  const RegistrationDialog = ({id, isOpen, formData, onConfirm, onCancel}) => {
    const [t, _] = useTranslation();
    const fields = formData.map(
            (field) => <FormField
              key={field.key}
              name={field.key}
              type={field.type || "text"}
              required={!field.optional}
              error={field.error}
              defaultValue={field.defaultValue}/>
          );
    const description = formData.length > 1 ?
      t('giftcard.registrationFormDescription', {token:token}) :
      t('giftcard.activationCodeDescription', {token:token});


    return <Dialog isOpen={isOpen} id={id} onClose={handleCloseDialog}>
        <h2>{t('giftcard.registrationForm')}</h2>
        <p>{description}</p>
        <form onSubmit={handleSubmit} onReset={handleActivateLaterClick}>
          {fields}
          <button type="submit" className="button shown">{t('giftcard.submitActivationForm')}</button>
          <button type="reset" className="button aux">{t('giftcard.activateLater')}</button>
        </form>
    </Dialog>
  }

  console.log("CardUserRegistrationForm rendering", regDialogOpen)

  return (
    <div className="registrationForm" ref={ref}>
      <button className="button shown activateBtn" onClick={handleClickActivate}>{t('giftcard.activateCard')}</button>
      <RegistrationDialog formData={formData} isOpen={regDialogOpen} id="registrationDialog" onClose={handleCloseDialog}/>
    </div>
  )
});


export default CardUserRegistrationForm;