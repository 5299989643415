
import HTTP from "./http";

const API = (baseUrl = '') => {

    baseUrl = baseUrl || (window.GIFTSTARR_CFG && window.GIFTSTARR_CFG.API_URL) || '/api'
    const http = HTTP(baseUrl);

    const CRUNCH_ACTION_CODES = {
        OK: "000"
    }

    const getGiftInfo= async (code) => {
        if(!code) return {error:"missing gift code"}
        return http.get('/getGiftInfo/'+code)
    }

    const scheduleEmailToBeneficiary = async (code, giftInfoUpdate={}) => {
        if(!code) return {error:"missing gift code"}
        return http.post('/sendVideoMessage/' + code, giftInfoUpdate)
    }

    const getClientConfig = async () => {
        return http.get('/clientConfig')
    }

    const cardCheck = async (token) => {
        return http.get('/card/' + token);
    }

    const balanceCheck = async (token) => {
        return http.get('/balance/' + token);
    }

    const cardStatement = async (token) => {
        return http.get('/statement/' + token);
    }



    const getVideoUrl = async (token) => {
        return http.get("/video/" + token)
    }

    const deleteVideo = async (token) => {
        return http.del("/video/" + token)
    }

    const sendMarketingEnquiry = async (form) => {
        return http.post("/marketing/enquiry", form);
    }

    const registerCardUser = async (user, token) => {
        return http.post("/giftcardusers/register/" + token, user);
    }

    const activateCard = async(token, acivationCode) => {
        return http.get("/activate/" + token + "?code=" + acivationCode);
    }


  const upload = async (file, token, onProgress, onStart=null) => {
      const url = baseUrl + "/video/" + token;
      const promise = new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.upload.addEventListener('progress', e => onProgress(e.loaded / e.total));
          xhr.addEventListener('load', () => resolve({status: xhr.status, body: xhr.responseText}));
          xhr.addEventListener('error', () => reject(new Error('File upload failed')));
          xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
          xhr.addEventListener('timeout', () => reject(new Error('File upload timed out')));
          xhr.timeout = 60000;
          xhr.open('POST', url, true);
          const formData = new FormData();
          formData.append("file", file);
          console.log(formData);
          if(onStart) onStart(xhr);
          xhr.send(formData);
      });
      return promise;
  }

    return {
        getGiftInfo  : getGiftInfo,
        scheduleEmailToBeneficiary: scheduleEmailToBeneficiary,
        getClientConfig: getClientConfig,
        balanceCheck : balanceCheck,
        cardCheck : cardCheck,
        cardStatement : cardStatement,
        uploadFiles : upload,
        getVideoUrl : getVideoUrl,
        deleteVideo : deleteVideo,
        sendMarketingEnquiry : sendMarketingEnquiry,
        registerCardUser: registerCardUser,
        activateCard: activateCard,

        ACTION_CODE: CRUNCH_ACTION_CODES,


    }
}

export default API