import API from "../api";
import {EVENTS} from "../reducer";

const api = API()

const getCodeFromQueryString = (search) => {
    const code = new URLSearchParams(search).get("code") || "";
    const match = code.match(/^[a-z0-9]{8}/)
    const accessCode = match ? match[0] : ""
    console.log("Home code:", code, "; sanitised:",accessCode,"; state:")
    return accessCode
}

const loadGiftInfo = async (code) => {
    const giftInfo = await api.getGiftInfo(code)
    const event = {type:EVENTS.GOT_GIFT_INFO, payload:giftInfo}
    return event;
}

const reduceTrxInfo = (transactions) => {
    const result = transactions.map(t => {return {...t, transactionDateTime: t.transactionDate + 'T' + t.transactionTime}})
      .sort((a,b) => a.transactionDateTime < b.transactionDateTime ? -1 : 1)
      .reduce((acc, trx) => {
        const transLink = trx.transLink
        if(transLink && acc.some(prevTrx => prevTrx.transLink === transLink)) return acc; // Thredd presentment
        if(trx.transactionType == "CLEARING") return acc; // Nymcard clearing has no link to auth transaction
        else return [...acc, trx];
    }, []);
    return result
}


export {getCodeFromQueryString, loadGiftInfo, reduceTrxInfo}