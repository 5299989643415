// export const COUNTRIES = [
//     { name: "Afghanistan", code: "AFG" },
//     { name: "Albania", code: "ALB" },
//     { name: "Algeria", code: "DZA" },
//     { name: "Andorra", code: "AND" },
//     { name: "Angola", code: "AGO" },
//     { name: "Antigua and Barbuda", code: "ATG" },
//     { name: "Argentina", code: "ARG" },
//     { name: "Armenia", code: "ARM" },
//     { name: "Australia", code: "AUS" },
//     { name: "Austria", code: "AUT" },
//     { name: "Azerbaijan", code: "AZE" },
//     { name: "Bahamas", code: "BHS" },
//     { name: "Bahrain", code: "BHR" },
//     { name: "Bangladesh", code: "BGD" },
//     { name: "Barbados", code: "BRB" },
//     { name: "Belarus", code: "BLR" },
//     { name: "Belgium", code: "BEL" },
//     { name: "Belize", code: "BLZ" },
//     { name: "Benin", code: "BEN" },
//     { name: "Bhutan", code: "BTN" },
//     { name: "Bolivia", code: "BOL" },
//     { name: "Bosnia and Herzegovina", code: "BIH" },
//     { name: "Botswana", code: "BWA" },
//     { name: "Brazil", code: "BRA" },
//     { name: "Brunei Darussalam", code: "BRN" },
//     { name: "Bulgaria", code: "BGR" },
//     { name: "Burkina Faso", code: "BFA" },
//     { name: "Burundi", code: "BDI" },
//     { name: "Cabo Verde", code: "CPV" },
//     { name: "Cambodia", code: "KHM" },
//     { name: "Cameroon", code: "CMR" },
//     { name: "Canada", code: "CAN" },
//     { name: "Central African Republic", code: "CAF" },
//     { name: "Chad", code: "TCD" },
//     { name: "Chile", code: "CHL" },
//     { name: "China", code: "CHN" },
//     { name: "Colombia", code: "COL" },
//     { name: "Comoros", code: "COM" },
//     { name: "Congo", code: "COG" },
//     { name: "Congo, Democratic Republic of the", code: "COD" },
//     { name: "Costa Rica", code: "CRI" },
//     { name: "Croatia", code: "HRV" },
//     { name: "Cuba", code: "CUB" },
//     { name: "Cyprus", code: "CYP" },
//     { name: "Czech Republic", code: "CZE" },
//     { name: "Denmark", code: "DNK" },
//     { name: "Djibouti", code: "DJI" },
//     { name: "Dominica", code: "DMA" },
//     { name: "Dominican Republic", code: "DOM" },
//     { name: "Ecuador", code: "ECU" },
//     { name: "Egypt", code: "EGY" },
//     { name: "El Salvador", code: "SLV" },
//     { name: "Equatorial Guinea", code: "GNQ" },
//     { name: "Eritrea", code: "ERI" },
//     { name: "Estonia", code: "EST" },
//     { name: "Eswatini", code: "SWZ" },
//     { name: "Ethiopia", code: "ETH" },
//     { name: "Fiji", code: "FJI" },
//     { name: "Finland", code: "FIN" },
//     { name: "France", code: "FRA" },
//     { name: "Gabon", code: "GAB" },
//     { name: "Gambia", code: "GMB" },
//     { name: "Georgia", code: "GEO" },
//     { name: "Germany", code: "DEU" },
//     { name: "Ghana", code: "GHA" },
//     { name: "Greece", code: "GRC" },
//     { name: "Grenada", code: "GRD" },
//     { name: "Guatemala", code: "GTM" },
//     { name: "Guinea", code: "GIN" },
//     { name: "Guinea-Bissau", code: "GNB" },
//     { name: "Guyana", code: "GUY" },
//     { name: "Haiti", code: "HTI" },
//     { name: "Honduras", code: "HND" },
//     { name: "Hungary", code: "HUN" },
//     { name: "Iceland", code: "ISL" },
//     { name: "India", code: "IND" },
//     { name: "Indonesia", code: "IDN" },
//     { name: "Iran", code: "IRN" },
//     { name: "Iraq", code: "IRQ" },
//     { name: "Ireland", code: "IRL" },
//     { name: "Israel", code: "ISR" },
//     { name: "Italy", code: "ITA" },
//     { name: "Jamaica", code: "JAM" },
//     { name: "Japan", code: "JPN" },
//     { name: "Jordan", code: "JOR" },
//     { name: "Kazakhstan", code: "KAZ" },
//     { name: "Kenya", code: "KEN" },
//     { name: "Kiribati", code: "KIR" },
//     { name: "Korea, Democratic People's Republic of", code: "PRK" },
//     { name: "Korea, Republic of", code: "KOR" },
//     { name: "Kuwait", code: "KWT" },
//     { name: "Kyrgyzstan", code: "KGZ" },
//     { name: "Lao People's Democratic Republic", code: "LAO" },
//     { name: "Latvia", code: "LVA" },
//     { name: "Lebanon", code: "LBN" },
//     { name: "Lesotho", code: "LSO" },
//     { name: "Liberia", code: "LBR" },
//     { name: "Libya", code: "LBY" },
//     { name: "Liechtenstein", code: "LIE" },
//     { name: "Lithuania", code: "LTU" },
//     { name: "Luxembourg", code: "LUX" },
//     { name: "Madagascar", code: "MDG" },
//     { name: "Malawi", code: "MWI" },
//     { name: "Malaysia", code: "MYS" },
//     { name: "Maldives", code: "MDV" },
//     { name: "Mali", code: "MLI" },
//     { name: "Malta", code: "MLT" },
//     { name: "Marshall Islands", code: "MHL" },
//     { name: "Mauritania", code: "MRT" },
//     { name: "Mauritius", code: "MUS" },
//     { name: "Mexico", code: "MEX" },
//     { name: "Micronesia (Federated States of)", code: "FSM" },
//     { name: "Moldova", code: "MDA" },
//     { name: "Monaco", code: "MCO" },
//     { name: "Mongolia", code: "MNG" },
//     { name: "Montenegro", code: "MNE" },
//     { name: "Morocco", code: "MAR" },
//     { name: "Mozambique", code: "MOZ" },
//     { name: "Myanmar", code: "MMR" },
//     { name: "Namibia", code: "NAM" },
//     { name: "Nauru", code: "NRU" },
//     { name: "Nepal", code: "NPL" },
//     { name: "Netherlands", code: "NLD" },
//     { name: "New Zealand", code: "NZL" },
//     { name: "Nicaragua", code: "NIC" },
//     { name: "Niger", code: "NER" },
//     { name: "Nigeria", code: "NGA" },
//     { name: "North Macedonia", code: "MKD" },
//     { name: "Norway", code: "NOR" },
//     { name: "Oman", code: "OMN" },
//     { name: "Pakistan", code: "PAK" },
//     { name: "Palau", code: "PLW" },
//     { name: "Panama", code: "PAN" },
//     { name: "Papua New Guinea", code: "PNG" },
//     { name: "Paraguay", code: "PRY" },
//     { name: "Peru", code: "PER" },
//     { name: "Philippines", code: "PHL" },
//     { name: "Poland", code: "POL" },
//     { name: "Portugal", code: "PRT" },
//     { name: "Qatar", code: "QAT" },
//     { name: "Romania", code: "ROU" },
//     { name: "Russian Federation", code: "RUS" },
//     { name: "Rwanda", code: "RWA" },
//     { name: "Saint Kitts and Nevis", code: "KNA" },
//     { name: "Saint Lucia", code: "LCA" },
//     { name: "Saint Vincent and the Grenadines", code: "VCT" },
//     { name: "Samoa", code: "WSM" },
//     { name: "San Marino", code: "SMR" },
//     { name: "Sao Tome and Principe", code: "STP" },
//     { name: "Saudi Arabia", code: "SAU" },
//     { name: "Senegal", code: "SEN" },
//     { name: "Serbia", code: "SRB" },
//     { name: "Seychelles", code: "SYC" },
//     { name: "Sierra Leone", code: "SLE" },
//     { name: "Singapore", code: "SGP" },
//     { name: "Slovakia", code: "SVK" },
//     { name: "Slovenia", code: "SVN" },
//     { name: "Solomon Islands", code: "SLB" },
//     { name: "Somalia", code: "SOM" },
//     { name: "South Africa", code: "ZAF" },
//     { name: "South Sudan", code: "SSD" },
//     { name: "Spain", code: "ESP" },
//     { name: "Sri Lanka", code: "LKA" },
//     { name: "Sudan", code: "SDN" },
//     { name: "Suriname", code: "SUR" },
//     { name: "Sweden", code: "SWE" },
//     { name: "Switzerland", code: "CHE" },
//     { name: "Syrian Arab Republic", code: "SYR" },
//     { name: "Tajikistan", code: "TJK" },
//     { name: "Tanzania, United Republic of", code: "TZA" },
//     { name: "Thailand", code: "THA" },
//     { name: "Timor-Leste", code: "TLS" },
//     { name: "Togo", code: "TGO" },
//     { name: "Tonga", code: "TON" },
//     { name: "Trinidad and Tobago", code: "TTO" },
//     { name: "Tunisia", code: "TUN" },
//     { name: "Turkey", code: "TUR" },
//     { name: "Turkmenistan", code: "TKM" },
//     { name: "Tuvalu", code: "TUV" },
//     { name: "Uganda", code: "UGA" },
//     { name: "Ukraine", code: "UKR" },
//     { name: "United Arab Emirates", code: "ARE" },
//     { name: "United Kingdom", code: "GBR" },
//     { name: "United States of America", code: "USA" },
//     { name: "Uruguay", code: "URY" },
//     { name: "Uzbekistan", code: "UZB" },
//     { name: "Vanuatu", code: "VUT" },
//     { name: "Venezuela", code: "VEN" },
//     { name: "Viet Nam", code: "VNM" },
//     { name: "Yemen", code: "YEM" },
//     { name: "Zambia", code: "ZMB" },
//     { name: "Zimbabwe", code: "ZWE" }
// ];
export const COUNTRIES = [
    { name: "Afghanistan", code: "AFG", phoneCode: "+93" },
    { name: "Albania", code: "ALB", phoneCode: "+355" },
    { name: "Algeria", code: "DZA", phoneCode: "+213" },
    { name: "Andorra", code: "AND", phoneCode: "+376" },
    { name: "Angola", code: "AGO", phoneCode: "+244" },
    { name: "Antigua and Barbuda", code: "ATG", phoneCode: "+1-268" },
    { name: "Argentina", code: "ARG", phoneCode: "+54" },
    { name: "Armenia", code: "ARM", phoneCode: "+374" },
    { name: "Australia", code: "AUS", phoneCode: "+61" },
    { name: "Austria", code: "AUT", phoneCode: "+43" },
    { name: "Azerbaijan", code: "AZE", phoneCode: "+994" },
    { name: "Bahamas", code: "BHS", phoneCode: "+1-242" },
    { name: "Bahrain", code: "BHR", phoneCode: "+973" },
    { name: "Bangladesh", code: "BGD", phoneCode: "+880" },
    { name: "Barbados", code: "BRB", phoneCode: "+1-246" },
    { name: "Belarus", code: "BLR", phoneCode: "+375" },
    { name: "Belgium", code: "BEL", phoneCode: "+32" },
    { name: "Belize", code: "BLZ", phoneCode: "+501" },
    { name: "Benin", code: "BEN", phoneCode: "+229" },
    { name: "Bhutan", code: "BTN", phoneCode: "+975" },
    { name: "Bolivia", code: "BOL", phoneCode: "+591" },
    { name: "Bosnia and Herzegovina", code: "BIH", phoneCode: "+387" },
    { name: "Botswana", code: "BWA", phoneCode: "+267" },
    { name: "Brazil", code: "BRA", phoneCode: "+55" },
    { name: "Brunei Darussalam", code: "BRN", phoneCode: "+673" },
    { name: "Bulgaria", code: "BGR", phoneCode: "+359" },
    { name: "Burkina Faso", code: "BFA", phoneCode: "+226" },
    { name: "Burundi", code: "BDI", phoneCode: "+257" },
    { name: "Cabo Verde", code: "CPV", phoneCode: "+238" },
    { name: "Cambodia", code: "KHM", phoneCode: "+855" },
    { name: "Cameroon", code: "CMR", phoneCode: "+237" },
    { name: "Canada", code: "CAN", phoneCode: "+1" },
    { name: "Central African Republic", code: "CAF", phoneCode: "+236" },
    { name: "Chad", code: "TCD", phoneCode: "+235" },
    { name: "Chile", code: "CHL", phoneCode: "+56" },
    { name: "China", code: "CHN", phoneCode: "+86" },
    { name: "Colombia", code: "COL", phoneCode: "+57" },
    { name: "Comoros", code: "COM", phoneCode: "+269" },
    { name: "Congo", code: "COG", phoneCode: "+242" },
    { name: "Congo, Democratic Republic of the", code: "COD", phoneCode: "+243" },
    { name: "Costa Rica", code: "CRI", phoneCode: "+506" },
    { name: "Croatia", code: "HRV", phoneCode: "+385" },
    { name: "Cuba", code: "CUB", phoneCode: "+53" },
    { name: "Cyprus", code: "CYP", phoneCode: "+357" },
    { name: "Czech Republic", code: "CZE", phoneCode: "+420" },
    { name: "Denmark", code: "DNK", phoneCode: "+45" },
    { name: "Djibouti", code: "DJI", phoneCode: "+253" },
    { name: "Dominica", code: "DMA", phoneCode: "+1-767" },
    { name: "Dominican Republic", code: "DOM", phoneCode: "+1-809" },
    { name: "Ecuador", code: "ECU", phoneCode: "+593" },
    { name: "Egypt", code: "EGY", phoneCode: "+20" },
    { name: "El Salvador", code: "SLV", phoneCode: "+503" },
    { name: "Equatorial Guinea", code: "GNQ", phoneCode: "+240" },
    { name: "Eritrea", code: "ERI", phoneCode: "+291" },
    { name: "Estonia", code: "EST", phoneCode: "+372" },
    { name: "Eswatini", code: "SWZ", phoneCode: "+268" },
    { name: "Ethiopia", code: "ETH", phoneCode: "+251" },
    { name: "Fiji", code: "FJI", phoneCode: "+679" },
    { name: "Finland", code: "FIN", phoneCode: "+358" },
    { name: "France", code: "FRA", phoneCode: "+33" },
    { name: "Gabon", code: "GAB", phoneCode: "+241" },
    { name: "Gambia", code: "GMB", phoneCode: "+220" },
    { name: "Georgia", code: "GEO", phoneCode: "+995" },
    { name: "Germany", code: "DEU", phoneCode: "+49" },
    { name: "Ghana", code: "GHA", phoneCode: "+233" },
    { name: "Greece", code: "GRC", phoneCode: "+30" },
    { name: "Grenada", code: "GRD", phoneCode: "+1-473" },
    { name: "Guatemala", code: "GTM", phoneCode: "+502" },
    { name: "Guinea", code: "GIN", phoneCode: "+224" },
    { name: "Guinea-Bissau", code: "GNB", phoneCode: "+245" },
    { name: "Guyana", code: "GUY", phoneCode: "+592" },
    { name: "Haiti", code: "HTI", phoneCode: "+509" },
    { name: "Honduras", code: "HND", phoneCode: "+504" },
    { name: "Hungary", code: "HUN", phoneCode: "+36" },
    { name: "Iceland", code: "ISL", phoneCode: "+354" },
    { name: "India", code: "IND", phoneCode: "+91" },
    { name: "Indonesia", code: "IDN", phoneCode: "+62" },
    { name: "Iran", code: "IRN", phoneCode: "+98" },
    { name: "Iraq", code: "IRQ", phoneCode: "+964" },
    { name: "Ireland", code: "IRL", phoneCode: "+353" },
    { name: "Israel", code: "ISR", phoneCode: "+972" },
    { name: "Italy", code: "ITA", phoneCode: "+39" },
    { name: "Jamaica", code: "JAM", phoneCode: "+1-876" },
    { name: "Japan", code: "JPN", phoneCode: "+81" },
    { name: "Jordan", code: "JOR", phoneCode: "+962" },
    { name: "Kazakhstan", code: "KAZ", phoneCode: "+7" },
    { name: "Kenya", code: "KEN", phoneCode: "+254" },
    { name: "Kiribati", code: "KIR", phoneCode: "+686" },
    { name: "Korea, Democratic People's Republic of", code: "PRK", phoneCode: "+850" },
    { name: "Korea, Republic of", code: "KOR", phoneCode: "+82" },
    { name: "Kuwait", code: "KWT", phoneCode: "+965" },
    { name: "Kyrgyzstan", code: "KGZ", phoneCode: "+996" },
    { name: "Lao People's Democratic Republic", code: "LAO", phoneCode: "+856" },
    { name: "Latvia", code: "LVA", phoneCode: "+371" },
    { name: "Lebanon", code: "LBN", phoneCode: "+961" },
    { name: "Lesotho", code: "LSO", phoneCode: "+266" },
    { name: "Liberia", code: "LBR", phoneCode: "+231" },
    { name: "Libya", code: "LBY", phoneCode: "+218" },
    { name: "Liechtenstein", code: "LIE", phoneCode: "+423" },
    { name: "Lithuania", code: "LTU", phoneCode: "+370" },
    { name: "Luxembourg", code: "LUX", phoneCode: "+352" },
    { name: "Madagascar", code: "MDG", phoneCode: "+261" },
    { name: "Malawi", code: "MWI", phoneCode: "+265" },
    { name: "Malaysia", code: "MYS", phoneCode: "+60" },
    { name: "Maldives", code: "MDV", phoneCode: "+960" },
    { name: "Mali", code: "MLI", phoneCode: "+223" },
    { name: "Malta", code: "MLT", phoneCode: "+356" },
    { name: "Marshall Islands", code: "MHL", phoneCode: "+692" },
    { name: "Mauritania", code: "MRT", phoneCode: "+222" },
    { name: "Mauritius", code: "MUS", phoneCode: "+230" },
    { name: "Mexico", code: "MEX", phoneCode: "+52" },
    { name: "Micronesia (Federated States of)", code: "FSM", phoneCode: "+691" },
    { name: "Moldova", code: "MDA", phoneCode: "+373" },
    { name: "Monaco", code: "MCO", phoneCode: "+377" },
    { name: "Mongolia", code: "MNG", phoneCode: "+976" },
    { name: "Montenegro", code: "MNE", phoneCode: "+382" },
    { name: "Morocco", code: "MAR", phoneCode: "+212" },
    { name: "Mozambique", code: "MOZ", phoneCode: "+258" },
    { name: "Myanmar", code: "MMR", phoneCode: "+95" },
    { name: "Namibia", code: "NAM", phoneCode: "+264" },
    { name: "Nauru", code: "NRU", phoneCode: "+674" },
    { name: "Nepal", code: "NPL", phoneCode: "+977" },
    { name: "Netherlands", code: "NLD", phoneCode: "+31" },
    { name: "New Zealand", code: "NZL", phoneCode: "+64" },
    { name: "Nicaragua", code: "NIC", phoneCode: "+505" },
    { name: "Niger", code: "NER", phoneCode: "+227" },
    { name: "Nigeria", code: "NGA", phoneCode: "+234" },
    { name: "North Macedonia", code: "MKD", phoneCode: "+389" },
    { name: "Norway", code: "NOR", phoneCode: "+47" },
    { name: "Oman", code: "OMN", phoneCode: "+968" },
    { name: "Pakistan", code: "PAK", phoneCode: "+92" },
    { name: "Palau", code: "PLW", phoneCode: "+680" },
    { name: "Panama", code: "PAN", phoneCode: "+507" },
    { name: "Papua New Guinea", code: "PNG", phoneCode: "+675" },
    { name: "Paraguay", code: "PRY", phoneCode: "+595" },
    { name: "Peru", code: "PER", phoneCode: "+51" },
    { name: "Philippines", code: "PHL", phoneCode: "+63" },
    { name: "Poland", code: "POL", phoneCode: "+48" },
    { name: "Portugal", code: "PRT", phoneCode: "+351" },
    { name: "Qatar", code: "QAT", phoneCode: "+974" },
    { name: "Romania", code: "ROU", phoneCode: "+40" },
    { name: "Russian Federation", code: "RUS", phoneCode: "+7" },
    { name: "Rwanda", code: "RWA", phoneCode: "+250" },
    { name: "Saint Kitts and Nevis", code: "KNA", phoneCode: "+1-869" },
    { name: "Saint Lucia", code: "LCA", phoneCode: "+1-758" },
    { name: "Saint Vincent and the Grenadines", code: "VCT", phoneCode: "+1-784" },
    { name: "Samoa", code: "WSM", phoneCode: "+685" },
    { name: "San Marino", code: "SMR", phoneCode: "+378" },
    { name: "Sao Tome and Principe", code: "STP", phoneCode: "+239" },
    { name: "Saudi Arabia", code: "SAU", phoneCode: "+966" },
    { name: "Senegal", code: "SEN", phoneCode: "+221" },
    { name: "Serbia", code: "SRB", phoneCode: "+381" },
    { name: "Seychelles", code: "SYC", phoneCode: "+248" },
    { name: "Sierra Leone", code: "SLE", phoneCode: "+232" },
    { name: "Singapore", code: "SGP", phoneCode: "+65" },
    { name: "Slovakia", code: "SVK", phoneCode: "+421" },
    { name: "Slovenia", code: "SVN", phoneCode: "+386" },
    { name: "Solomon Islands", code: "SLB", phoneCode: "+677" },
    { name: "Somalia", code: "SOM", phoneCode: "+252" },
    { name: "South Africa", code: "ZAF", phoneCode: "+27" },
    { name: "South Sudan", code: "SSD", phoneCode: "+211" },
    { name: "Spain", code: "ESP", phoneCode: "+34" },
    { name: "Sri Lanka", code: "LKA", phoneCode: "+94" },
    { name: "Sudan", code: "SDN", phoneCode: "+249" },
    { name: "Suriname", code: "SUR", phoneCode: "+597" },
    { name: "Sweden", code: "SWE", phoneCode: "+46" },
    { name: "Switzerland", code: "CHE", phoneCode: "+41" },
    { name: "Syrian Arab Republic", code: "SYR", phoneCode: "+963" },
    { name: "Tajikistan", code: "TJK", phoneCode: "+992" },
    { name: "Tanzania, United Republic of", code: "TZA", phoneCode: "+255" },
    { name: "Thailand", code: "THA", phoneCode: "+66" },
    { name: "Timor-Leste", code: "TLS", phoneCode: "+670" },
    { name: "Togo", code: "TGO", phoneCode: "+228" },
    { name: "Tonga", code: "TON", phoneCode: "+676" },
    { name: "Trinidad and Tobago", code: "TTO", phoneCode: "+1-868" },
    { name: "Tunisia", code: "TUN", phoneCode: "+216" },
    { name: "Turkey", code: "TUR", phoneCode: "+90" },
    { name: "Turkmenistan", code: "TKM", phoneCode: "+993" },
    { name: "Tuvalu", code: "TUV", phoneCode: "+688" },
    { name: "Uganda", code: "UGA", phoneCode: "+256" },
    { name: "Ukraine", code: "UKR", phoneCode: "+380" },
    { name: "United Arab Emirates", code: "ARE", phoneCode: "+971" },
    { name: "United Kingdom", code: "GBR", phoneCode: "+44" },
    { name: "United States of America", code: "USA", phoneCode: "+1" },
    { name: "Uruguay", code: "URY", phoneCode: "+598" },
    { name: "Uzbekistan", code: "UZB", phoneCode: "+998" },
    { name: "Vanuatu", code: "VUT", phoneCode: "+678" },
    { name: "Venezuela", code: "VEN", phoneCode: "+58" },
    { name: "Viet Nam", code: "VNM", phoneCode: "+84" },
    { name: "Yemen", code: "YEM", phoneCode: "+967" },
    { name: "Zambia", code: "ZMB", phoneCode: "+260" },
    { name: "Zimbabwe", code: "ZWE", phoneCode: "+263" }
];