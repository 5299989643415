

const ANY = "*";
const STATE_ENGINE = (props) => {

  const STATE_PROPS = props;
  const previousStates = [];

  class PropertyValueError extends Error {
    constructor(prop, val) {
      let message = `Property ${prop} cannot have the value of ${val}. Allowed: ${STATE_PROPS[prop]}`
      super(message);
      this.name = "ValidationError";
    }
  }

  let currentState = Object.keys(STATE_PROPS).reduce( (acc, key) => {
      acc[key] = (STATE_PROPS[key] instanceof Array) ?
        STATE_PROPS[key][0] :
        ""
      return acc;
    }, {}
  );

  let states = {};
  let relations = [];

  const isValueAllowed = (prop, val) => {
    return STATE_PROPS[prop].includes(val) || STATE_PROPS[prop] === ANY
  }

  const addState = (name, state) => {
    if(states[name]) throw new Error(`State ${name} already exists`);
    states[name] = {};
    for(let p in state) {
      if(!(p in STATE_PROPS)) throw new Error(`Property ${p} not found`)
      if(isValueAllowed(p, state[p])) {
        states[name][p] = state[p];
      } else {
        throw new PropertyValueError(p, state[p]);
      }
    }
    return myself;
  }

  const addComponentToPropRelation = (comp, prop) => {
    const equals = (r) => (r.comp === comp && r.prop === prop);
    if (prop in STATE_PROPS) {
      if (!relations.some(equals)) {
        relations.push({comp: comp, prop: prop});
      }
    } else {
      throw new Error(`Wrong relation, property ${prop} not found in ${STATE_PROPS}`);
    }
    return myself;
  }

  const addComponentToPropRelations = (relations) => {
    relations.forEach( rel => {addComponentToPropRelation(rel.comp, rel.prop); });
    return myself;
  }

  const applyCssClass = (ref, from, to, propName) => {
    if (ref.current) {
      const cl = ref.current.classList || ref.current.wrapper.classList;
      cl.remove(...STATE_PROPS[propName]);
      cl.add(to[propName]);
    }
  }

  const applyProperty = (ref, from, to, statePropName, targetPropName) => {
    const style = ref.current.style || ref.current.wrapper.style;
    if (style && style[targetPropName]) {
      if (to && to[statePropName]) {
        style[targetPropName] = to[statePropName][targetPropName];
      }
    }
  }

  const applyState = (toStateName, overrides = {}) => {
    const toState = get(toStateName);
    const fromState = get(previousState());
    if(toStateName) previousStates.push(toStateName);
    console.log("----- STATE CHANGE ----->", toStateName, " | extras:", overrides);
    relations.forEach(item => {
      if(item.prop in toState) {
        if(!isValueAllowed(item.prop, toState[item.prop]))
          throw new PropertyValueError(item.prop, toState[item.prop]);

        applyCssClass(item.comp, fromState, toState, item.prop);

        if(currentState[item.prop] !== toState[item.prop])
          console.log("change: ", item.prop, currentState[item.prop], " --> ", toState[item.prop])
        currentState[item.prop] = toState[item.prop];
      }
    })
    return myself;
  }

  const get = (name=null, overrides={}) => {
    if(!name)
      return {...currentState, ...overrides};
    else {
      return {...states[name], ...overrides};
    }
  }

  const previousState = () => {
    if(previousStates && previousStates.length > 0) {
      return previousStates[previousStates.length - 1];
    } else
      return {}
  }


  const myself = {
    addState: addState,
    addComponentToPropRelations: addComponentToPropRelations,
    applyState: applyState,
    get: get,
    previousState: previousState
  }
  return myself
}

export default STATE_ENGINE;
export {ANY};