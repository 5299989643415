import React, {useEffect, useRef, useState} from "react";
import router, {useRouterContext} from "../router";
import {useTranslation} from "react-i18next";
import API from "../api";
import "./giftcard-home.css"
import {FileUploadButton} from "../file-upload";
import {Button, ButtonGroup} from "../buttons";
import STATE from "./giftcard-screen-state"
import {
  BrandHeader,
  CardArtwork,
  InfoPanel, InputTokenForm,
  LanguageSwitch, MicrositeFooter,
  Statement,
  VideoPanel,
  VideoWrapper
} from "./giftcard-home-comps";
import CardUserRegistrationForm from "./giftcarduser-form";
import ProgressBar from "../progress-bar";
import {reduceTrxInfo} from "../utils/backendUtils";


const GiftcardHome = () => {
  console.log("rendering GiftcardHome")
  const AVAILABLE_LANGS = ['ar','en','es','it','pl'];
  const {t, i18n} = useTranslation();
  const [clientBaseUrl, setClientBaseUrl] = useState("");
  const [clientFooterLinks, setClientFooterLinks] = useState([]);
  const [langList, setLangList] = useState([]);
  const [routerState, dispatch] = useRouterContext();
  const [token, setToken] = useState("");
  const [infoText, setInfoText] = useState("");
  const [videoUrl, setVideoUrl] = useState(null);
  const [card, setCard] = useState();
  const [progress, setProgress] = useState(0);
  const [statement, setStatment] = useState(false);
  const api = API();


  const uploadInProgress = useRef(null);
  const brandHeaderRef = useRef(null);
  const infoPanelRef = useRef(null);
  const cardArtworkRef = useRef(null);
  const videoRef = useRef(null);
  const videoWrapperRef = useRef(null);
  const buttonGroupRef = useRef(null);
  const buttonDeleteVideoRef = useRef(null);
  const buttonAddVideoMessageRef = useRef(null);
  const buttonListTrxsRef = useRef(null);
  const buttonConfirmRef = useRef(null);
  const buttonBackToHomeRef = useRef(null);
  const buttonUploadRef = useRef(null);
  const statementRef = useRef(null);
  const registrationFormRef = useRef(null);
  const inputTokenRef = useRef(null);

  STATE.addComponentToPropRelations([
    {comp: brandHeaderRef, prop: "brandHeader"},
    {comp: videoRef, prop: "videoPanel"},
    {comp: videoWrapperRef, prop: "videoWrapper"},
    {comp: cardArtworkRef, prop: "cardArtwork"},
    {comp: infoPanelRef, prop: "infoPanel"},
    {comp: buttonGroupRef, prop: "buttonGroup"},
    {comp: buttonDeleteVideoRef, prop: "buttonDeleteVideo"},
    {comp: buttonAddVideoMessageRef, prop: "buttonAddVideoMessage"},
    {comp: buttonUploadRef, prop: "buttonAddVideo"},
    {comp: buttonConfirmRef, prop: "buttonConfirm"},
    {comp: buttonListTrxsRef, prop: "buttonListTrxs"},
    {comp: buttonBackToHomeRef, prop: "buttonBackToHome"},
    {comp: statementRef, prop: "transactionList"},
    {comp: registrationFormRef, prop: "registrationForm"},
    {comp: inputTokenRef, prop: "inputTokenForm"}
  ]);

  const scrollToSeeButtons = () => {
    buttonGroupRef.current.scrollIntoView('smooth');
  }

  const handleError = (error, msg)  => {
      console.error(error);
      STATE.applyState("error" );
      setInfoText(`<span>${t('giftcard.errorQueryingCard')}</span>`)
  }


  const handleDeleteVideo = async (e) => {
    await api.deleteVideo(token);
    setVideoUrl(null);
    setInfoText(t('giftcard.videoDeleted'));
    setTimeout(()=>{
        STATE.applyState("default");
        loadCardDetails();
      }, 1000);

  }


  const loadCardInfo = async (tkn) => {
      return api.cardCheck(tkn).then(
          async (response) => {
            if(response.error) throw new Error(response);
            if(response.actionCode !== "000") throw new Error(response.actionCodeDescription);
            return response;
          }
      ).catch(
        error => handleError(error, t('giftcard.errorQueryingCard'))
      );
  }

  const loadStatement = async (tkn) => {
      return api.cardStatement(tkn).then(
          response => {
            if(response.error) throw new Error(response);
            if(response.actionCode !== "000") throw new Error(response.actionCodeDescription);
            const transactions = reduceTrxInfo(response.transactions);
            setStatment(transactions);
          }
      ).catch( error => {
          console.error(error);
      })
  }

  const loadVideo = async (tkn) => {
      return api.getVideoUrl(tkn).then(
          response => {
            if(response.error || response.status_code) throw new Error(response.detail);
            // the backend may think it's HTTP and not HTTPS
            let url = response.replace('http:', window.location.protocol);
            setVideoUrl(url);
          }
      ).catch( error => {
          console.error(error);
          setVideoUrl("");
      })
  }

  const getTokenFromUrl = () => {
    return routerState.route.params.token;
  }

  const loadCardDetails = async () => {
    const tkn = getTokenFromUrl();
    setToken(tkn);

    console.log("load all details");

    const info = await loadCardInfo(tkn);
    console.log("info", info);
    if(!info) {
      handleError({},"error loading card details");
    } else {
      setCard(info);
      loadVideo(tkn);
      if(info.isLive) {
        setInfoText(`<span>${t('giftcard.availableBalance', info)}</span>`)
        loadStatement(tkn);
      } else {
        setInfoText(`<span>${t('giftcard.cardNotActivated')}</span>`)
        STATE.applyState("home_register_to_activate");
      }
    }
  }

  const loadClientConfig = async () => {
    const cfg = await api.getClientConfig();
    setClientBaseUrl(cfg.base_url);
    const langs = AVAILABLE_LANGS.filter(x => cfg.languages && cfg.languages.includes(x));
    console.debug("languages configured:", langs, cfg);
    setLangList(langs);
    if(cfg.footer) {
      setClientFooterLinks(cfg.footer.filter(item => item.type === "link"));
    }
  }

  const showHome = () => {
    abortUploadIfAny();
    console.log("HOME", statement, videoUrl, card);
    const trxCount = statement ? statement.length : 0;
    // has at least one trx and the last one (the only one) is not a LOAD
    const hasTransactions = trxCount > 0 && statement[trxCount - 1].transactionType !== "L";

    let stateName;

    if(hasTransactions) {
      stateName = videoUrl ? "giftee_home_has_video" : "giftee_home_no_video";
    } else {
      stateName = videoUrl ? "home_just_purchased_has_video" : "home_just_purchased";
    }
    STATE.applyState(stateName);
  }


  useEffect( () => {
    const tkn = getTokenFromUrl();
    if(!tkn) {
      STATE.applyState("home_missing_token");
    } else {
      console.log("delay loading details for a sec")
      setInfoText(t('giftcard.loadingDetails'));
      STATE.applyState("loading");
      setTimeout(loadCardDetails, 1000);
    }
  }, [routerState.route.params.token])

  useEffect( () => {
    if(card && statement && videoUrl !== null) {
      if(['default', 'loading'].includes(STATE.previousState())) showHome();
    }
  }, [card, statement, videoUrl])

  useEffect(() => {
    loadClientConfig();
  }, []);

  const handleBackToHomeClick = () => {
    loadCardDetails();
    showHome();
  }

  const handleFileSelected = (fileSelected) => {
    const url = URL.createObjectURL(fileSelected);
    setVideoUrl(url);
    STATE.applyState("uploading" );
  }

  const abortUploadIfAny = () => {
    if(uploadInProgress.current) {
      uploadInProgress.current.abort();
      console.log("aborting previous upload");
    }
  }

  const handleAddVideoMessage = () => {
    STATE.applyState("add_video_info");
    setInfoText(t('giftcard.addingVideoInstruction'));
  }

  const handleUploadStart = (xhr) => {
    abortUploadIfAny();
    uploadInProgress.current = xhr;
    setInfoText(t('giftcard.uploadingYourVideo'));
  }

  const handleFileUploaded = (response) => {
    STATE.applyState("uploaded");
    setInfoText(t('giftcard.uploadSuccessful'));
  };

  const handleFileProgress = (percent) => {
    console.log(percent);
    videoWrapperRef.current.style.left = `-${percent}%`;
    setProgress(percent);
  };

  const handleFileError = (error) => {
    STATE.applyState("uploadError");
    setInfoText(t('giftcard.uploadErrorTryAgain'));
  };

  const handleConfirmVideo = (e) => {
    STATE.applyState("default");
    loadCardDetails();
  }

  const handleActivationSuccess = () => {
    const cardUpdated = {...card, isLive: true};
    setCard(cardUpdated);
  }

  return <>
        <div className={"contents"} >
          <LanguageSwitch i18next={i18n} langs={langList}/>
          <BrandHeader className="large" ref={brandHeaderRef}/>
          <InputTokenForm className="hidden" ref={inputTokenRef}/>
          <InfoPanel text={infoText} ref={infoPanelRef}/>
          <CardArtwork ref={cardArtworkRef} isActive={card && card.isLive}/>
          {card && !card.isLive ?
            <CardUserRegistrationForm
              ref={registrationFormRef}
              token={token}
              visibility="hidden"
              handleReset={showHome}
              handleSuccess={handleActivationSuccess}
            /> : null
          }
          <VideoWrapper ref={videoWrapperRef}>
            <ProgressBar value={progress} />
            <VideoPanel videoUrl={videoUrl} ref={videoRef} />
          </VideoWrapper>
          <ButtonGroup className={"buttonPanel"} ref={buttonGroupRef}>
            <Button label={t("giftcard.addVideoMessage")}
                    className="half"
                    onClick={handleAddVideoMessage}
                    ref={buttonAddVideoMessageRef}/>
            <Button label={t("giftcard.deleteVideo")}
                    className="half"
                    onClick={handleDeleteVideo}
                    ref={buttonDeleteVideoRef}/>
            <Button label={t("giftcard.confirm")}
                    className="submit half"
                    onClick={handleConfirmVideo}
                    ref={buttonConfirmRef}/>
            <FileUploadButton label={t("giftcard.addVideo")} className="half"
                              onFileSelected={handleFileSelected}
                              onUploadStart={handleUploadStart}
                              onFileUploaded={handleFileUploaded}
                              onProgress={handleFileProgress}
                              onError={handleFileError}
                              ref={buttonUploadRef}
            />

            <Button label={t("giftcard.backToHomeScreen")}
                    onClick={handleBackToHomeClick}
                    ref={buttonBackToHomeRef}
                    className="link"/>
          </ButtonGroup>
          <Statement
            transactions={statement}
            token={token}
            className="hidden"
            ref={statementRef}/>
          <MicrositeFooter links={clientFooterLinks} baseUrl={clientBaseUrl}/>
        </div>

    </>
}

export default GiftcardHome
