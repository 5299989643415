class GiftcardUser {
    FIELDS = {
        id: "integer",
        first_name: "string",
        last_name: "string",
        date_of_birth: "date",
        country_of_birth: "string",
        country_of_residency: "string",
        mobile_number: "string",
        email: "string",
        consent: "boolean"
    }
    constructor(data) {

        console.log('data fields', data)
        console.log(Object.keys(this.FIELDS));
        Object.keys(this.FIELDS).forEach(field => {
            console.log("setting field " + field + " to " + data[field]);
            this[field] = data[field]}
        );
    }

    toObject() {
        let result = {};
        Object.keys(this.FIELDS).forEach(field => {
            result[field] = this[field];
        });
        return result;
    }

    validate() {
        let result = {valid: true, errors: []};
        if (!this.first_name) {
            result.errors.push({field: 'first_name', message: 'giftcarduser.firstName.required'});
        }
        if(!this.last_name) {
            result.errors.push({field: 'last_name', message: 'giftcarduser.lastName.required'});
        }
        if(!this.date_of_birth) {
            result.errors.push({field: 'date_of_birth', message: 'giftcarduser.dateOfBirth.required'});
        }
        if(!this.country_of_birth) {
            result.errors.push({field: 'country_of_birth', message: 'giftcarduser.countryOfBirth.required'});
        }
        if(!this.country_of_residency) {
            result.errors.push({field: 'country_of_residency', message: 'giftcarduser.countryOfResidency.required'});
        }
        if(!this.mobile_number) {
            result.errors.push({field: 'mobile_number', message: 'giftcarduser.mobileNumber.required'});
        }
        if(!this.email) {
            result.errors.push({field: 'email', message: 'giftcarduser.email.required'});
        }
        if(!this.consent) {
            result.errors.push({field: 'consent', message: 'giftcarduser.consent.required'});
        }
        result.valid = result.errors.length === 0;
        return result;

    }
}

export default GiftcardUser